<template>
    <div>
        <admin-template>
            <!-- Modal Edit/Add -->
            <edit-add-process :data-src="selectedProcess" @end="getProcesses" />

            <!-- Delete -->
            <modal :name="'confirm-delete'">
                <div v-if="selectedProcess" class="p-6 text-center">
                    <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <h3 class="mb-5 text-lg font-normal text-gray-500">Souhaitez-vous vraiment supprimer <b>{{selectedProcess.name}}</b> ?</h3>
                    <button @click="deleteProcess" data-modal-toggle="popup-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                        Oui je suis sûr
                    </button>
                    <button @click="$modal.hide('confirm-delete')" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">Non, annuler</button>
                </div>
            </modal>


            <!-- Actions -->
            <div class="mb-5 flex justify-between items-center">
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input v-model="searchInput" @input="searchProcess" type="search" id="default-search" class="block w-full p-3 pl-10 text-sm text-gray-900 border border-slate-200 rounded-lg bg-white focus:outline-none" placeholder="Rechercher un nom">
                </div>
                
                <button @click="$modal.show('edit-add-process'); selectedProcess = null" type="button" class="text-white bg-emerald-500 hover:bg-emerald-600 duration-200 rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none">
                    <i class="fas fa-plus mr-1"></i> Ajouter une procédure
                </button>
            </div>

            <!-- Table data -->
            <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left text-gray-500 rounded-xl">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3 px-6">
                                Nom
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Description
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Dossier
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="loading">
                            <tr v-for="i in 15" :key="'tr-cell-loading-' + i" class="bg-white">
                                <td class="px-2 py-4" v-for="(i) in 4" :key="'cell-loading-' + i">
                                    <div role="status" class="max-w-sm animate-pulse"><div class="h-2.5 bg-gray-200 rounded-full w-full mx-auto mb-4"></div></div>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="!loading && processes.length <= 0" class="bg-white">
                            <td class="py-4 px-6 text-center" colspan="8">Aucun résultat</td>
                        </tr>
                        <tr v-for="(process, index) in processes" :key="'cell-' + index" class="bg-white hover:bg-slate-50 duration-100 border-b">
                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                {{process.name}}
                            </th>
                            <td class="py-4 px-6">
                                {{process.description}}
                            </td>
                            <td class="py-4 px-6">
                                {{process.directory}}
                            </td>
                            <td class="py-4 px-6">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-3">
                                    <button @click="updateProcess(process)" class="bg-slate-400 hover:bg-slate-500 duration-200 py-2 px-4 text-white rounded-lg text-xs">
                                        <i class="fas fa-cog mr-1"></i> Configurer
                                    </button>
                                    <button @click="displayConfirmDeleteModal(process)" class="bg-red-500 hover:bg-red-600 duration-200 py-2 px-4 text-white rounded-lg text-xs">
                                        <i class="fas fa-trash mr-1"></i> Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="my-10 text-right">
                    <pagination
                    :current-length="processes.length"
                    :length="defaultProcesses.length"
                    :current="currentPage"
                    :per-page="itemsPerPage"
                    @change="changePage"
                    />
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import AdminTemplate from '../components/AdminTemplate.vue'
import EditAddProcess from '../components/EditAddProcess.vue'
import Pagination from '../components/Pagination.vue'

import apiService from '@/services/api.service'

export default {
    components: { AdminTemplate, EditAddProcess, Pagination },

    data() {
        return {
            defaultProcesses: [],
            processes: [],
            selectedProcess: null,
            loading: false,
            itemsPerPage: 20,
            currentPage: 1,
            searchInput: '',
        }
    },

    methods: {
        setProcessItems() {
            this.processes = this.defaultProcesses.slice((this.itemsPerPage * (this.currentPage - 1)), (this.itemsPerPage * (this.currentPage)))
        },

        async getProcesses() {
            this.loading = true
            const response = await apiService.post('all_process')
            this.defaultProcesses = response.data.posts.post
            this.loading = false
            this.setProcessItems()
        },

        updateProcess(process) {
            this.$modal.show('edit-add-process')
            this.selectedProcess = process
        },

        async deleteProcess() {
            await apiService.post('remove_process', {
                process_id: this.selectedProcess.id
            })
            this.$modal.hide('confirm-delete')
            this.getProcesses()
        },

        displayConfirmDeleteModal(process) {
            this.selectedProcess = process
            this.$modal.show('confirm-delete')
        },

        searchProcess() {
            if (this.searchInput) {
                this.processes = this.defaultProcesses.filter((process) => process.name.toLowerCase().startsWith(this.searchInput.toLowerCase()));
            } else {
                this.setProcessItems()
            }
        },

        changePage(index) {
            this.currentPage = index
            this.setProcessItems()
        },
    },
    
    mounted() {
        this.getProcesses()
    }
}
</script>
