<template>
    <div>
        <modal name="edit-add-alias">
            <div class="flex items-center justify-between">
                <h1 class="text-xl font-bold">
                    <template v-if="dataSrc">
                        <img :src="dataSrc.style.logo" v-if="dataSrc.style.logo" class="h-10" />
                        <div class="text-2xl font-bold" :style="'color:' + dataSrc.style.css.defaultBgColor" v-else>{{dataSrc.name}}</div>
                    </template>
                    <template v-else>
                        <i class="fas fa-circle-plus mr-2"></i> Ajouter un espace
                    </template>
                </h1>
                <button @click="$modal.hide('edit-add-alias')" class="text-red-500 hover:text-red-600 text-2xl duration-200 py-2 px-4 rounded-xl">
                    <i class="fas fa-times"></i>
                </button>
            </div>

            <!-- Menu -->
            <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-2">
                <ul class="flex flex-wrap -mb-px">
                    <li @click="selectMenu('settings')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('settings') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-cog mr-1"></i> <span class="" v-if="menuIsSelect('settings')">Paramètres</span>
                        </span>
                    </li>
                    <li @click="selectMenu('design')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('design') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-paintbrush mr-1"></i> <span v-if="menuIsSelect('design')">Design</span>
                        </span>
                    </li>
                    <li @click="selectMenu('api')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('api') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-network-wired mr-1"></i> <span v-if="menuIsSelect('api')">Api</span>
                        </span>
                    </li>
                    <li @click="selectMenu('production')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('production') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-server mr-1"></i> <span v-if="menuIsSelect('production')">Production</span>
                        </span>
                    </li>
                    <li @click="selectMenu('options')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('options') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-plug mr-1"></i> <span v-if="menuIsSelect('options')">Options</span>
                        </span>
                    </li>
                    <li @click="selectMenu('texts')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('texts') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-comment-dots mr-1"></i> <span v-if="menuIsSelect('texts')">Textes</span>
                        </span>
                    </li>
                    <li @click="selectMenu('smtp')" class="mr-2 cursor-pointer">
                        <span class="inline-block p-4 rounded-t-lg" :class="menuIsSelect('smtp') ? 'border-b-2 border-blue-600 active text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">
                            <i class="fas fa-at mr-1"></i> <span v-if="menuIsSelect('smtp')">SMTP</span>
                        </span>
                    </li>
                </ul>
            </div>

            <!-- Paramètres -->
            <div class="mt-6" v-if="data && menuSelected == 'settings'">
                <div class="bg-gray-100 p-8 rounded-xl">
                    <div class="mb-6">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Nom de l'espace</label>
                        <input v-model="data.name" type="text" id="name" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="mb-6">
                        <label for="url_keyname" class="block text-sm font-medium text-gray-900">Mot clé dans l'url</label>
                        <div class="text-xs mt-1 mb-2"><span class="font-light">Exemple: https://savinfo.fr/</span><b>{{dataSrc && dataSrc.url_keyname ? dataSrc.url_keyname : '{mot-cle}'}}</b></div>
                        <input v-model="data.url_keyname" :disabled="dataSrc && dataSrc.url_keyname" type="text" id="url_keyname" :class="dataSrc ? 'bg-gray-200' : 'bg-gray-50'" class="mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="mb-6">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Procédure</label>
                        <select v-model="data.process_id" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                            <option v-for="(process, processIndex) in processes" :value="process.id" :key="'process-' + processIndex">
                                {{process.name}}
                            </option>
                        </select>
                    </div>
                    <div class="mb-6">
                        <div class="flex items-center mb-2">
                            <label for="overwrite_folder" class="block text-sm font-medium text-gray-900">Dossier de surcharge</label>
                            <div class="text-xs ml-2 font-light opacity-60">Optionnel</div>
                        </div>
                        <input v-model="data.overwrite_folder" type="text" id="overwrite_folder" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                </div>
            </div>
            
            <!-- Style -->
            <div class="mt-6" v-if="data && menuSelected == 'design'">
                <div class="bg-gray-100 p-8 rounded-xl mb-6">
                    <div class="mb-4">
                        <label for="logo" class="block mb-2 text-sm font-medium text-gray-900">Url du logo</label>
                        <input v-model="style.logo" type="text" id="logo" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="mb-4">
                        <label for="logo-height" class="block text-sm font-medium text-gray-900">Taille du logo</label>
                        <div class="text-xs mt-1 mb-2"><span class="font-light">Hauteur</span></div>
                        <input v-model="style.css.headerLogoHeight" type="text" id="logo-height" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="mb-4">
                        <label for="favicon" class="block mb-2 text-sm font-medium text-gray-900">Url du favicon</label>
                        <input v-model="style.favicon" type="text" id="favicon" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-900">Cacher le nom de l'espace</label>
                            <div class="text-xs mt-1 mb-2"><span class="font-light">Dans le header</span></div>
                            <toggle-button v-model="style.headerHideBrand"/>
                        </div>
                        <div v-if="!style.headerHideBrand" class="mb-4">
                            <label for="favicon" class="block mb-2 text-sm font-medium text-gray-900">Slogan</label>
                            <input v-model="data.slogan" type="text" id="favicon" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-900">Afficher la page intermédiaire Pro Particulier</label>
                        <div class="text-xs mt-1 mb-2"><span class="font-light">Page professionnelle inscription</span></div>
                        <toggle-button v-model="data.pro_particulier"/>
                    </div>
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-900">L'utilisateur doit être connecté</label>
                        <div class="text-xs mt-1 mb-2"><span class="font-light">Avant d'accéder au formulaire</span></div>
                        <toggle-button v-model="data.login_oblig"/>
                    </div>
                </div>
                <div class="grid md:grid-cols-3 gap-8 mb-6">
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Couleur par défaut</label>
                        <verte picker="square" model="hex" v-model="style.css.defaultBgColor"></verte>
                    </div>
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Couleur texte séléctionné</label>
                        <verte picker="square" model="hex" v-model="style.css.defaultTextSelected"></verte>
                    </div>
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Hover texte séléctionné</label>
                        <verte picker="square" model="hex" v-model="style.css.hoverDefaultTextSelected"></verte>
                    </div>
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Texte menu header</label>
                        <verte picker="square" model="hex" v-model="style.css.menuTextUnselected"></verte>
                    </div>
                </div>
                <div class="bg-gray-100 p-8 rounded-xl">
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Image par défaut</label>
                        <input v-model="images.default" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="mt-4">
                        <label class="block text-sm font-medium text-gray-900">Exceptions</label>

                        <div v-for="(image, imageIndex) in images.exceptions" :key="'img-' + imageIndex" class="flex items-center">
                            <div class="grow my-2 flex items-center">
                                <select v-model="image.route" class="w-1/3 bg-slate-800 text-white border-2 font-light border-slate-800 text-sm rounded-l-lg focus:outline-none block w-full p-2.5">
                                    <option v-for="(route, routeIndex) in allRoutes" :value="route.name" :key="'route-' + routeIndex">{{route.label}}</option>
                                </select>
                                <select v-model="image.type" :class="image.type == 'nothing' ? 'w-full rounded-r-lg' : 'w-1/3'" class="bg-white border-2 font-light border-white text-gray-900 text-sm focus:outline-none block w-full p-2.5">
                                    <option value="nothing">Aucune photo</option>
                                    <option value="image">Photo</option>
                                </select>
                                <input v-show="image.type != 'nothing'" v-model="image.url" type="text" placeholder="Url de l'image" class="font-light bg-gray-300 border border-gray-300 border-l-0 text-gray-900 text-sm rounded-r-lg focus:outline-none block w-full p-2.5">
                            </div>
                            <button @click="removeImage(imageIndex)" class="ml-2"><i class="fas fa-times text-red-500"></i></button>
                        </div>
                        <button @click="addImageException" class="bg-gray-300 hover:bg-gray-400 duration-200 py-2 px-4 rounded-xl text-xs font-light">
                            <i class="fas fa-plus mr-0.5"></i> Ajouter
                        </button>
                    </div>
                </div>
            </div>

            <!-- Api -->
            <div class="mt-6" v-if="data && menuSelected == 'api'">
                <div class="grid md:grid-cols-3 gap-8">
                    <div class="bg-gray-100 text-center p-8 rounded-xl mb-6">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Données de démonstration</label>
                        <toggle-button v-model="data.demo" />
                    </div>
                </div>

                <div class="bg-gray-100 p-8 rounded-xl">
                    <label for="api_keyname" class="block mb-2 text-sm font-medium text-gray-900">Mot clé renvoyé à l'api</label>
                    <input v-model="data.api_keyname" type="text" id="api_keyname" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                </div>
            </div>

            <!-- Production -->
            <div class="mt-6" v-if="data && menuSelected == 'production'">
                <div class="bg-gray-100 p-8 rounded-xl">
                    <div class="flex items-center mb-2">
                        <label for="domain_name" class="block text-sm font-medium text-gray-900">Url de production</label>
                        <div class="text-xs ml-2 font-light opacity-60">Optionnel</div>
                    </div>
                    <input @input="parseDomainName" v-model="data.url_domain_name" type="text" id="domain_name" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    <div class="mt-2 text-xs font-light opacity-60">{{data.domain_name}}</div>
                </div>
            </div>

            <!-- Options -->
            <div class="mt-6" v-if="data && menuSelected == 'options'">
                <div class="grid md:grid-cols-3 gap-8">
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Afficher sur mobile</label>
                        <toggle-button v-model="data.mobile" />
                    </div>
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Mettre les traductions</label>
                        <toggle-button v-model="data.translates" />
                    </div>
                    <div class="bg-gray-100 text-center p-8 rounded-xl">
                        <label class="block mb-2 text-sm font-medium text-gray-900">S'enregistrer à la newsletter</label>
                        <toggle-button v-model="data.newsletter" />
                    </div>
                </div>
            </div>

            <!-- Text -->
            <div class="mt-6" v-if="data && menuSelected == 'texts'">
                <overwrite-lang-alias v-if="data" v-model="data.texts" />
            </div>

            <!-- SMTP -->
            <div class="mt-6" v-if="data && menuSelected == 'smtp'">
                <div class="grid grid-cols-2 gap-5 mb-3">
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Host</label>
                        <input v-model="data.smtp_host" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Port</label>
                        <input v-model="data.smtp_port" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Login</label>
                        <input v-model="data.smtp_login" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                        <input v-model="data.smtp_pwd" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Secure</label>
                        <input v-model="data.smtp_secure" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">From</label>
                        <input v-model="data.smtp_from" type="text" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <button @click="submit" class="mt-6 bg-green-700 hover:bg-green-800 duration-200 py-3 px-5 text-white rounded-xl">
                <div v-show="!dataSrc">
                    <i class="fas fa-plus mr-1"></i> Créer l'espace
                </div>
                <div v-show="dataSrc">
                    <i class="fas fa-check mr-1"></i> Modifier
                </div>
            </button>
        </modal>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

import OverwriteLangAlias from './OverwriteLangAlias.vue';

import Verte from 'verte';
import 'verte/dist/verte.css';

export default {
    components: {Verte, OverwriteLangAlias},
    props: {
        dataSrc: {}
    },

    watch: {
        dataSrc: async function () {
            await this.initData()
            this.setDefaultData()
        }
    },

    computed: {
        allRoutes() {
            return [
                {label: 'Bienvenue', name: 'welcome'},
                {label: 'Formulaire', name: 'form.home'},
                {label: 'Accueil', name: 'home'},
                {label: 'Mes demandes', name: 'user.product.list'},
                {label: 'Modifier profil', name: 'user.update'},
                {label: 'Connexion', name: 'user.login'},
                {label: 'Inscription', name: 'user.register'},
                {label: 'Mot de passe oublié', name: 'user.password-forgot'},
            ]
        }
    },

    data() {
        return {
            mobile: true,
            data: null,
            style: null,
            processes: [],
            menuSelected: 'settings',
            imageDefault: '',
            images: {
                default: '',
                exceptions: []
            },
        }
    },

    methods: {
        selectMenu(type) {
            this.menuSelected = type
        },
        menuIsSelect(type) {
            return this.menuSelected == type ? true : false
        },
        async initData() {
            this.menuSelected = 'settings'

            const response = await apiService.post('all_process')
            this.processes = response.data.posts.post

            this.data = {
                name: null,
                url_keyname: null,
                domain_name: null,
                url_domain_name: null,
                api_keyname: null,
                overwrite_folder: null,
                mobile: true,
                translates: true,
                newsletter: false,
                process_id: null,
                texts: null,
                demo: true,
                smtp_host: null,
                smtp_login: null,
                smtp_pwd: null,
                smtp_port: null,
                smtp_secure: null,
                smtp_from: null,
                slogan: null,
                pro_particulier: null
            }
            this.style = {
                logo: null,
                favicon: null,
                headerHideBrand: false,
                css: {
                    defaultBgColor: '#111827',
                    defaultTextSelected: '#0ea5e9',
                    hoverDefaultTextSelected: '#0e8bc3',
                    menuTextUnselected: '#ffffff',
                    headerLogoHeight: null,
                }
            }
        },
        setDefaultData() {
            if (this.dataSrc) {
                this.data = null
                this.$nextTick(() => {
                    this.data = {
                        name: this.dataSrc.name,
                        url_keyname: this.dataSrc.url_keyname,
                        url_domain_name: this.dataSrc.domain_name == 'null' ? null : ('http://' + this.dataSrc.domain_name),
                        domain_name: this.dataSrc.domain_name == 'null' ? null : this.dataSrc.domain_name,
                        api_keyname: this.dataSrc.api_keyname,
                        overwrite_folder: this.dataSrc.overwrite_folder == 'null' ? null : this.dataSrc.overwrite_folder,
                        mobile: this.dataSrc.mobile == '1' ? true : false,
                        translates: this.dataSrc.translates == '1' ? true : false,
                        newsletter: this.dataSrc.newsletter == '1' ? true : false,
                        demo: this.dataSrc.demo == '1' ? true : false,
                        process_id: this.dataSrc.process_id,
                        texts: this.dataSrc.textes ? JSON.parse(this.dataSrc.textes) : '{"FR":[],"EN-GB":[],"DE":[],"ES":[],"IT":[],"PT-PT":[]}',
                        smtp_host: this.dataSrc.smtp_host == 'null' ? null : this.dataSrc.smtp_host,
                        smtp_login: this.dataSrc.smtp_login == 'null' ? null : this.dataSrc.smtp_login,
                        smtp_pwd: this.dataSrc.smtp_pwd == 'null' ? null : this.dataSrc.smtp_pwd,
                        smtp_port: this.dataSrc.smtp_port == 'null' ? null : this.dataSrc.smtp_port,
                        smtp_secure: this.dataSrc.smtp_secure == 'null' ? null : this.dataSrc.smtp_secure,
                        smtp_from: this.dataSrc.smtp_from == 'null' ? null : this.dataSrc.smtp_from,
                        slogan: this.dataSrc.slogan,
                        pro_particulier: this.dataSrc.pro_particulier == '1' ? true : false,
                        login_oblig: this.dataSrc.login_oblig == '1' ? true : false,
                    }
                })
                if (this.dataSrc.images) {
                    this.images = JSON.parse(this.dataSrc.images)
                }
                this.style = this.dataSrc.style
            }
        },
        async submit() {
            const url = this.dataSrc ? 'update_alias' : 'add_alias'

            if (!this.dataSrc) {
                if (!this.data.name) {
                    this.$toast.error("Nom de l'espace obligatoire")
                    return
                }
                if (!this.data.url_keyname) {
                    this.$toast.error("Mot clé dans l'url obligatoire")
                    return
                }
                if (!this.data.process_id) {
                    this.$toast.error("Procédure obligatoire")
                    return
                }
                const exist = await apiService.post('get_alias', {
                    url_keyname: this.data.url_keyname
                })
                if (exist.data.posts.post.length > 0) {
                    this.$toast.error("Le mot clé de l'url est déjà utilisé pour un autre espace")
                    return
                }
            }
            await apiService.post(url, {
                name: this.data.name,
                url_keyname: this.data.url_keyname,
                domain_name: this.data.domain_name,
                api_keyname: this.data.api_keyname,
                overwrite_folder: this.data.overwrite_folder,
                mobile: this.data.mobile ? 1 : 0,
                translates: this.data.translates ? 1 : 0,
                newsletter: this.data.newsletter ? 1 : 0,
                demo: this.data.demo ? 1 : 0,
                process_id: this.data.process_id,
                textes: JSON.stringify(this.data.texts),
                smtp_host: this.data.smtp_host,
                smtp_login: this.data.smtp_login,
                smtp_pwd: this.data.smtp_pwd,
                smtp_port: this.data.smtp_port,
                smtp_secure: this.data.smtp_secure,
                smtp_from: this.data.smtp_from,
                slogan: this.data.slogan,
                pro_particulier: this.data.pro_particulier ? 1 : 0,
                login_oblig: this.data.login_oblig ? 1 : 0,
                style: JSON.stringify({
                    logo: this.style.logo,
                    favicon: this.style.favicon,
                    headerHideBrand: this.style.headerHideBrand,
                    css: this.style.css
                }),
                images: JSON.stringify(this.images)
            })
            this.$toast.success(this.dataSrc ? 'Espace modifié' : 'Espace créé')
            this.$modal.hide('edit-add-alias')
            this.$emit('end')
        },

        parseDomainName() {
            try {
                const url = new URL(this.data.url_domain_name)
                this.data.domain_name = url.hostname
            } catch (e) {
                this.data.domain_name = null
            }
        },

        addImageException() {
            this.images.exceptions.push({
                route: 'welcome',
                type: 'image',
                url: '',
            })
        },

        removeImage(imageIndex) {
            this.images.exceptions.splice(imageIndex, 1)
        }
    },
    mounted() {
        this.initData()
        this.setDefaultData()
    }
}
</script>

<style lang="scss" scoped>

</style>
