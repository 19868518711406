import axios from 'axios'

import env from '@/../.env'
import eventHub from '@/eventHub'

var myaxios = axios.create({
    baseURL: env.API_URL
})

myaxios.interceptors.response.use(function (response) {
    if (response.data.posts && response.data.posts.error && response.data.posts.error != 0) {
        eventHub.$emit('toast-error', response.data.posts.error)
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default myaxios
