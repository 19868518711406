import Vue from 'vue'
import App from './App.vue'
import router from './router'

/* --------------
    COMPONENTS
---------------*/
Vue.component('modal', require('./components/Modal').default)
Vue.component('boolean-label', require('./components/BooleanLabel').default)
Vue.component('fold-card', require('./components/FoldCard').default)

/* --------------
    TOOLS
---------------*/
import ModalTool from './tools/ModalTool'
Vue.prototype.$modal = ModalTool

/* --------------
    PACKAGES
---------------*/
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

/* --------------
    REFRESH TOKEN
---------------*/
import ApiService from './services/api.service'
ApiService.refreshApiToken()

/* --------------
    START
---------------*/

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
