<template>
    <div>
        <!-- Header -->
        <div class="bg-slate-800 text-white py-6">
            <div class="container mx-auto px-7 2xl:px-0">
                <div class="flex items-center justify-between">
                    <div>
                        <span class="font-bold text-lg">SavInfo</span>
                        <span class="ml-2 font-light text-xs">- Espace administrateur</span>
                    </div>
                    <div class="flex items-center">
                        <dropdown-menu :overlay="false" :transition="null" direction="right" class="text-black mt-1">
                            <button class="text-white" slot="trigger">
                                <img class="h-6 rounded-full ml-5" :src="'https://avatars.dicebear.com/api/initials/' + userEmail + '.svg?background=%23bdbdbd'" alt="">
                            </button>

                            <div slot="body" class="bg-white divide-y divide-gray-100">                            
                                <router-link :to="{ name: 'Logout'}" style="text-decoration: none">
                                    <div class="py-2 text-center text-gray-600 cursor-pointer hover:text-gray-900">
                                        Déconnexion
                                    </div>
                                </router-link>
                            </div>
                        </dropdown-menu>
                    </div>
                </div>
            </div>
        </div>

        <!-- Menu -->
        <div class="container mx-auto mt-10 px-7 2xl:px-0">
            <div class="bg-white p-7 mb-10 rounded-xl">
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <router-link :to="{name: 'alias'}">
                            <div :class="isSelectClasses('alias')" class="border-r pr-5 mr-5 cursor-pointer">
                                <i class="fas fa-boxes-stacked mr-1"></i> Espaces
                            </div>
                        </router-link>
                        <router-link :to="{name: 'Processes'}">
                            <div :class="isSelectClasses('Processes')" class="pr-5 mr-5 cursor-pointer border-r">
                                <i class="fa-solid fa-microchip mr-1"></i> Procédures
                            </div>
                        </router-link>
                        <router-link :to="{name: 'Translates'}">
                            <div :class="isSelectClasses('Translates')" class="pr-5 mr-5 cursor-pointer">
                                <i class="fa-solid fa-bars mr-1"></i> Textes
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu'
import 'v-dropdown-menu/dist/v-dropdown-menu.css'    

export default {
    components: {
        DropdownMenu
    },

    computed: {
        userEmail() {
            let user = localStorage.getItem('user')
            if (user) {
                return JSON.parse(user).email
            }
            return ''
        }
    },

    methods: {
        isSelectClasses(keyname) {
            if (keyname == this.$route.name) {
                return 'font-bold'
            }
            return 'font-light opacity-40 hover:opacity-100 duration-200'
        }
    }
}
</script>
