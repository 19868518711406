<template>
    <div>
        <modal name="add-text">
            <label class="mb-1 text-xs font-bold text-gray-900">Nouveau texte en français</label>
            <textarea @input="newTextValueChange" v-model="newText.value" type="text" class="w-full border p-2 text-sm rounded focus:outline-none" placeholder="Valeur"></textarea>
            <div class="mb-2" v-if="newText.key">
                <label class="mb-1 text-xs font-bold text-gray-900">Clé</label>
                <div class="flex items-center gap-2">
                    <button class="" @click="copyNewKeyname"><i class="fa-solid fa-copy"></i></button>
                    <input type="text" v-model="newText.key" class="w-full col-span-2 border p-2 text-sm rounded focus:outline-none">
                </div>
            </div>
            <button @click="addTextInStack" class="mt-2 px-4 py-2 rounded text-sm bg-green-100 hover:bg-green-200 duration-200 ">Enregistrer</button>
        </modal>

        <modal name="edit-text">
            <label class="mb-1 text-xs font-bold text-gray-900">Editer texte en français</label>
            <template v-if="selectedText">
                <textarea v-model="selectedText.value" type="text" class="w-full col-span-2 border p-2 text-sm rounded focus:outline-none" placeholder="Valeur"></textarea>
                <label class="mb-1 text-xs font-bold text-gray-900">Clé</label>
                <div @click="copyKeyname" class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-2 rounded">{{ selectedText.key }}</div>
                <button @click="editTextInStack" class="mt-2 px-4 py-2 rounded text-sm bg-green-100 hover:bg-green-200 duration-200 ">Enregistrer</button>
            </template>
        </modal>

        <modal name="confirm-delete">
            <template v-if="selectedText">
                <div class="bg-slate-50 p-4 rounded mb-2">Souhaitez-vous réellement supprimer le texte <b>{{ selectedText.key }}</b> ?</div>
                <button class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded" @click="deleteText">Confirmer</button>
            </template>
        </modal>

        <admin-template>
            <div v-if="langs && langs.length > 0" class="p-5 my-10 bg-white border rounded-xl">
                <div class="mb-4 pb-4 border-b">
                    <button @click="newText = {}; $modal.show('add-text')" class="px-4 py-2 rounded text-sm bg-slate-100 hover:bg-slate-200 duration-200">Ajouter un texte</button>
                </div>
                <!-- Filters -->
                <div class="grid grid-cols-6 gap-2 items-center border-b pb-4 mb-4">
                    <select v-model="selectedFolder" type="text" class="border p-2 text-sm rounded focus:outline-none" placeholder="Dossier">
                        <option :value="item" v-for="(item, itemIndex) in listFolders" :key="'folder-item-' + itemIndex">{{ item }}</option>
                    </select>
                    <input v-model="filterKey" @input="applyFilters" type="text" class="col-span-2 border p-2 text-sm rounded focus:outline-none" placeholder="Clé" />
                    <input v-model="filterValue" @input="applyFilters" type="text" class="col-span-3 border p-2 text-sm rounded focus:outline-none" placeholder="Valeur" />
                </div>
                <!-- Langs -->
                <div class="flex justify-end mb-4 gap-2 items-center">
                    Langue: 
                    <select @change="selectLang" v-model="selectedLang" type="text" class="border p-2 text-sm rounded focus:outline-none" placeholder="Langue">
                        <option :value="lang" v-for="(lang, langIndex) in langs" :key="'lang-' + langIndex">{{ lang.label }}</option>
                    </select>
                </div>
                <!-- Texts list -->
                <div v-for="(text, textIndex) in texts" :key="'item-' + textIndex">
                    <div class="grid grid-cols-4 items-center gap-4 mb-3">
                        <div class="w-full flex items-center gap-4">
                            <button @click="selectedText = {...text}; $modal.show('confirm-delete')"><i class="fas fa-trash hover:text-red-500 cursor-pointer"></i></button>
                            <button @click="selectedText = {...text}; $modal.show('edit-text')"><i class="fas fa-pen hover:text-blue-500 cursor-pointer"></i></button>
                            <div class="text-sm font-light" style="word-break:break-all">{{ text.key }}</div>
                        </div>
                        <div class="col-span-3">
                            <div class="bg-slate-100 p-2 rounded text-sm font-light">{{ text.value }}</div>
                            <!-- <textarea class="border w-full p-2" v-model="text.value"></textarea> -->
                        </div>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import AdminTemplate from '../components/AdminTemplate.vue'
import apiService from '@/services/api.service'

export default {
    components: { AdminTemplate },

    watch: {
        selectedFolder() {
            if (this.selectedFolder) {
                this.filterKey = (this.filterKey ? this.filterKey : '') + this.selectedFolder + '.'
                this.selectedFolder = null
                this.applyFilters()
            }
        }
    },

    data() {
        return {
            texts: [],
            filterKey: null,
            filterValue: null,
            selectedFolder: null,
            langs: [],
            selectedLang: null,
            newText: {},
            selectedText: null
        }
    },

    computed: {
        listFolders() {
            let endArray = []
            if (this.texts) {
                this.texts.forEach((item) => {
                    let key = null
                    if (this.filterKey) {
                        key = item.key.split(this.filterKey)[1]
                        if (key && key.includes('.')) {
                            key = key.split('.')[0]
                        } else {
                            key = null
                        }
                    } else {
                        key = item.key.split('.')[0]
                    }
                    if (key && !endArray.find((item) => item == key)) {
                        endArray.push(key)
                    }
                })
            }
            return endArray
        }
    },
    
    methods: {
        async getAllLangs() {
            let response = await apiService.post('getalllang', {})
            this.langs = response.data.posts.post
            this.langs.forEach((lang) => {
                lang.contenu = this.convertContenu(JSON.parse(lang.contenu));
            });
            this.selectedLang = this.langs[0]
            this.selectLang()
        },

        selectLang() {
            this.texts = this.selectedLang.contenu
            this.applyFilters()
        },

        convertContenu(contenu) {
            return Object.keys(contenu).map((key) => {return {key: key, value: contenu[key]}})
        },

        applyFilters() {
            let endArray = this.selectedLang.contenu
            if (this.filterKey) {
                endArray = endArray.filter((line) => line.key.toLowerCase().startsWith(this.filterKey.toLowerCase()))
            }
            if (this.filterValue) {
                endArray = endArray.filter((line) => line.value.toLowerCase().startsWith(this.filterValue.toLowerCase()))
            }
            this.texts = endArray
        },

        async addTextInStack() {
            if (!this.newText.value) {
                this.$toast.error('Le texte est obligatoire')
                return
            }
            let text = this.newText.value
            let key = this.newText.key
            for (let langIndex in this.langs) {
                if (this.langs[langIndex].lang != 'FR') {
                    let response = await apiService.post('trad', {
                        langue: this.langs[langIndex].lang,
                        contenu: this.newText.value
                    })
                    text = JSON.parse(response.data.posts.post).translations[0].text
                } else {
                    text = this.newText.value
                }
                this.langs[langIndex].contenu.unshift({
                    key: key,
                    value: text
                })
                this.langs[langIndex].contenu = this.langs[langIndex].contenu.sort((a, b) => a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1)
            }
            this.texts = this.selectedLang.contenu
            this.applyFilters()
            this.$modal.hide('add-text')
            this.newText = {}
            await this.save()
            this.$toast.success('Mot clé ajouté')
        },

        async save() {
            for (let langIndex in this.langs) {
                let endObj = {}
                this.langs[langIndex].contenu.forEach((item) => {
                    endObj[item.key] = item.value
                })
                await apiService.post('update_lang', {
                    id_lang: this.langs[langIndex].id,
                    langue: this.langs[langIndex].lang,
                    label: this.langs[langIndex].label,
                    contenu: JSON.stringify(endObj),
                })
            }
        },

        newTextValueChange() {
            let strNoAccent = (a) => {
                var b = "áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ",
                    c = "aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY",
                    d = ""
                for (var i = 0, j = a.length; i < j; i++) {
                    var e = a.substr(i, 1)
                    d += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e
                }
                return d
            }

            this.newText.key = strNoAccent(this.newText.value).toLowerCase()
            // eslint-disable-next-line
            this.newText.key = this.newText.key.replaceAll(/[.,\/#!$%\^&\*';:{}=\-`~()]/g, " ")
            this.newText.key = this.newText.key.replace(/ +(?= )/g,'');
            this.newText.key = this.newText.key.replaceAll(' ', '_')
            if (this.newText.key.slice(-1) == '_') {
                this.newText.key = this.newText.key.slice(0, -1)
            }
            if (this.filterKey) {
                this.newText.key = this.filterKey + this.newText.key
            }
        },
        copyNewKeyname() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;

            clipboardData.writeText(this.newText.key);
            this.$toast.success('Mot clé copié')
        },
        copyKeyname() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;

            clipboardData.writeText(this.selectedText.key);
            this.$toast.success('Mot clé copié')
        },
        deleteText() {
            let index = this.selectedLang.contenu.findIndex((item) => item.key == this.selectedText.key)
            if (index >= 0) {
                this.selectedLang.contenu.splice(index, 1)
                this.$modal.hide('confirm-delete')
                this.save()
            }
        },
        async editTextInStack() {
            if (!this.selectedText.value) {
                this.$toast.error('Le texte est obligatoire')
                return
            }

            for (let langIndex in this.langs) {
                let index = this.langs[langIndex].contenu.findIndex((item) => item.key == this.selectedText.key)
                if (index >= 0) {
                    if (this.langs[langIndex].lang == 'FR') {
                        this.langs[langIndex].contenu[index].value = this.selectedText.value
                    } else {
                        let response = await apiService.post('trad', {
                            langue: this.langs[langIndex].lang,
                            contenu: this.selectedText.value
                        })
                        this.langs[langIndex].contenu[index].value = JSON.parse(response.data.posts.post).translations[0].text
                    }
                }
            }
            this.texts = this.selectedLang.contenu
            this.applyFilters()
            this.$modal.hide('edit-text')
            await this.save()
            this.$toast.success('Texte modifié')
        }
    },
    async mounted () {
        this.getAllLangs()
    },
}
</script>

<style lang="scss" scoped>

</style>