import myaxios from './axios.service'

import env from '@/../.env'
import eventHub from '@/eventHub'

function convertObjToFormData(keyname, obj)
{
    var formData = new FormData();

    // Convert all parameters to formData
    for (var key in obj) {
        formData.append(key, obj[key]);
    }

    // Connect to the API
    formData.append('operation', keyname)
    formData.append('id', env.API_ID)
    formData.append('token', env.API_TOKEN)

    return formData
}

const ApiService = {
    refreshApiToken: async function() {
        var formData = new FormData();
        formData.append('action', 'refresh_tokenAPI')
        formData.append('id', '777')
        formData.append('token', env.API_TOKEN)

        let responseToken = await myaxios.post('', formData)
        localStorage.setItem('api-token', responseToken.data.posts.post)
    },

    sendRequest: async function (keyname, obj) {
        // Init data
        let isValid = false
        let response = null
        let tryLimit = 0

        while (!isValid && tryLimit++ < 10) {
            // Get response data
            response = await myaxios.post('', convertObjToFormData(keyname, obj), {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('api-token')}`
                }
            })

            // Refresh token
            if (response.data.posts.error == 'token expire' || response.data.posts.error == 'Authentification Failed') {
                await this.refreshApiToken()
            } else {
                // break while
                isValid = true

                // Display error toast
                if (response.data.posts && response.data.posts.error && response.data.posts.error != 0) {
                    eventHub.$emit('toast-error', response.data.posts.error)
                }
            }
        }
        return response
    },

    post: async function (keyname, obj) {
        return await this.sendRequest(keyname, obj)
    },

    text: async function(keyname, id_proc, id_case = null) {
        let responseText = await this.post('get_text', {
            id_case: id_case,
            id_proc: id_proc,
            keyname: keyname
        })
        return responseText.data.posts.post
    }
}
export default ApiService
