import eventHub from '@/eventHub'

function show(name) {
    eventHub.$emit('display-modal', name)
}

function hide(name) {
    eventHub.$emit('hide-modal', name)
}

var ModalTool = {
    show,
    hide
}

export default ModalTool
