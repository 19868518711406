<template>
    <div>
        <modal name="edit-add-process">
            <div class="flex items-center justify-between">
                <h1 class="text-xl font-bold">
                    <template v-if="dataSrc && dataSrc.id">
                        <i class="fas fa-cog mr-2"></i> Modifier une procédure
                    </template>
                    <template v-else>
                        <i class="fas fa-circle-plus mr-2"></i> Ajouter une procédure
                    </template>
                </h1>
                <button @click="$modal.hide('edit-add-process')" class="text-red-500 hover:text-red-600 text-2xl duration-200 py-2 px-4 rounded-xl">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <hr class="mt-5 mb-5">
            <div v-if="data" class="bg-gray-100 p-8 rounded-xl mb-6">
                <div class="mb-6">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Nom du process</label>
                    <input v-model="data.name" type="text" id="first_name" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                </div>
                <div class="mb-4">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                    <textarea v-model="data.description" rows="5" type="text" id="first_name" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5"></textarea>
                </div>
                <div class="mb-4">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Nom du fichier de config</label>
                    <input v-model="data.directory" type="text" id="first_name" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                </div>
            </div>
            <button @click="submit" class="mt-2 bg-green-700 hover:bg-green-800 duration-200 py-3 px-5 text-white rounded-xl">
                <div v-show="!dataSrc">
                    <i class="fas fa-plus mr-1"></i> Enregistrer
                </div>
                <div v-show="dataSrc">
                    <i class="fas fa-check mr-1"></i> Modifier
                </div>
            </button>
        </modal>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    props: {
        dataSrc: {}
    },

    watch: {
        dataSrc: function () {
            this.initData()
            this.setDefaultData()
        }
    },

    data() {
        return {
            data: null
        }
    },

    methods: {
        initData() {
            this.data = {
                name: null,
                description: null,
                directory: null
            }
        },
        setDefaultData() {
            if (this.dataSrc) {
                this.data = {
                    name: this.dataSrc.name,
                    description: this.dataSrc.description,
                    directory: this.dataSrc.directory,
                }
            }
        },

        async submit() {
            const url = this.dataSrc ? 'update_process' : 'add_process'

            await apiService.post(url, {
                process_name: this.data.name,
                process_directory: this.data.directory,
                process_description: this.data.description,
                process_id: this.dataSrc ? this.dataSrc.id : null
            })
            this.initData()
            this.$toast.success('Process créé')
            this.$modal.hide('edit-add-process')
            this.$emit('end')
        }
    },

    mounted() {
        this.initData()
    }
}
</script>

<style lang="scss" scoped>

</style>
