<template>
    <div>
        <div v-if="display">
            <div @click="display = false" class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
                <div @click.stop="" class="w-full p-12 bg-white rounded-xl xl:w-1/2 lg:w-2/3" style="max-height: 90vh; overflow-y: auto;">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from '@/eventHub'

export default {
    props: {
        name: {
            required: true
        },
    },
    data() {
        return {
            display: false
        }
    },

    methods: {
        displayModal() {
            this.display = true
        },

        hideModal() {
            this.display = false
        }
    },

    mounted() {
        eventHub.$on('display-modal', (name) => {
            if (name == this.name) {
                this.displayModal()
            }
        })

        eventHub.$on('hide-modal', (name) => {
            if (name == this.name) {
                this.hideModal()
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
