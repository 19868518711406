import Vue from 'vue'
import VueRouter from 'vue-router'

// Components
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Alias from '../views/Alias.vue'
import Processes from '../views/Processes.vue'
import Translates from '../views/Translates.vue'

Vue.use(VueRouter)

const routes = [
    {path: '/login', name: 'Login', component: Login},
    {path: '/logout', name: 'Logout', component: Logout},
    {path: '/', name: 'Home', redirect: {name: 'alias'}},
    {path: '/alias', name: 'alias', component: Alias},
    {path: '/processes', name: 'Processes', component: Processes},
    {path: '/translates', name: 'Translates', component: Translates}
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next)=>{
    if (to.name !== 'Login' && !localStorage.getItem('user') ){
        next({name: 'Login', replace: true})
    } else {
        next();
    }
})

export default router
