<template>
    <div>
        <div class="">
            <div @click="open = !open" :class="open ? 'rounded-t-xl font-bold bg-gray-50' : 'rounded-xl'" class="border p-4 hover:bg-gray-50 cursor-pointer duration-200 flex justify-between items-center">
                {{title}}
                <div v-show="!open"><i class="fas fa-chevron-down"></i></div>
                <div v-show="open"><i class="fas fa-chevron-up"></i></div>
            </div>
            <div class="p-4 border border-t-0 rounded-b-xl" v-if="open">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
