<template>
    <div>
        <nav aria-label="Page navigation example">
            <ul class="inline-flex items-center -space-x-px">
                <li>
                    <div @click="current > 1 ? changePage(current - 1) : null" class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">
                        <span class="sr-only">Précédent</span>
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    </div>
                </li>
                <li v-for="i in nbPage" :key="'paginate-' + i">
                    <div @click="changePage(i)" class="cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-white border" :class="i == current ? 'bg-slate-200 border-slate-200' : 'border-gray-200 hover:bg-gray-100 hover:text-gray-700'">
                        {{i}}
                    </div>
                </li>
                <li>
                    <div @click="current + 2 < length ? changePage(current + 1) : null" class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">
                        <span class="sr-only">Suivant</span>
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    </div>
                </li>
            </ul>
        </nav>
        <div class="font-light text-xs mt-2">{{currentLength}} {{currentLength > 1 ? 'résultats ' : ' résultat'}} sur {{length}}</div>
    </div>
</template>

<script>
export default {
    props: {
        currentLength: {},
        length: {},
        current: {},
        perPage: {}
    },

    computed: {
        nbPage() {
            return Math.ceil(this.length / this.perPage)
        }
    },

    methods: {
        changePage(index) {
            this.$emit('change', index)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
