<template>
    <div>
        <admin-template>
            <!-- Modal Edit/Add -->
            <edit-add-alias :data-src="selectedAlias" @end="getAliases" />

            <!-- Delete -->
            <modal :name="'confirm-delete'">
                <div v-if="selectedAlias" class="p-6 text-center">
                    <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <h3 class="mb-5 text-lg font-normal text-gray-500">Souhaitez-vous vraiment supprimer <b>{{selectedAlias.name}}</b> ?</h3>
                    <button @click="deleteAlias" data-modal-toggle="popup-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                        Oui je suis sûr
                    </button>
                    <button @click="$modal.hide('confirm-delete')" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">Non, annuler</button>
                </div>
            </modal>

            <!-- Actions -->
            <div class="mb-5 flex justify-between items-center">
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input v-model="searchInput" @input="searchAlias" type="search" id="default-search" class="block w-full p-3 pl-10 text-sm text-gray-900 border border-slate-200 rounded-lg bg-white focus:outline-none" placeholder="Rechercher un nom">
                </div>
                
                <button @click="displayAddModal" type="button" class="text-white bg-emerald-500 hover:bg-emerald-600 duration-200 rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none">
                    <i class="fas fa-plus mr-1"></i> Créer un espace
                </button>
            </div>

            <!-- Table data -->
            <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left text-gray-500 rounded-xl">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3 px-6">
                                Nom
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Url
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Nom de domaine
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Api
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Production
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Process
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="loading">
                            <tr v-for="i in 15" :key="'tr-cell-loading-' + i" class="bg-white">
                                <td class="px-2 py-4" v-for="(i) in 8" :key="'cell-loading-' + i">
                                    <div role="status" class="max-w-sm animate-pulse"><div class="h-2.5 bg-gray-200 rounded-full w-full mx-auto mb-4"></div></div>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="!loading && aliases.length <= 0" class="bg-white">
                            <td class="py-4 px-6 text-center" colspan="8">Aucun résultat</td>
                        </tr>
                        <tr v-for="(space, index) in aliases" :key="'cell-' + index" class="bg-white hover:bg-slate-50 duration-100 border-b">
                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                <img class="max-w-20 max-h-8" v-if="space.style.logo" :src="space.style.logo" alt="">
                                <div class="text-2xl font-bold" :style="'color:' + space.style.css.defaultBgColor" v-else>
                                    {{space.name}}
                                </div>
                            </th>
                            <td class="py-4 px-6">
                                <a class="text-blue-500 underline" target="_blank" :href="aliasUrl(space)">/{{space.url_keyname}}</a>
                            </td>
                            <td class="py-4 px-6">
                                {{space.domain_name == 'null' ? '-' : space.domain_name}}
                            </td>
                            <td class="py-4 px-6">
                                {{space.api_keyname}}
                            </td>
                            <td class="py-4 px-6">
                                <boolean-label :value="space.demo == '0' ? true : false" />
                            </td>
                            <td class="py-4 px-6">
                                {{space.process_name}}
                            </td>
                            <td class="py-4 pr-6">
                                <div class="grid grid-cols-1 xl:grid-cols-2 gap-3">
                                    <button @click="updateAlias(space)" class="bg-slate-400 hover:bg-slate-500 duration-200 py-2 px-4 text-white rounded-lg text-xs">
                                        <i class="fas fa-cog"></i> Configurer
                                    </button>
                                    <button @click="displayConfirmDeleteModal(space)" class="bg-red-500 hover:bg-red-600 duration-200 py-2 px-4 text-white rounded-lg text-xs">
                                        <i class="fas fa-trash"></i> Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="my-10 text-right">
                    <pagination
                    :current-length="aliases.length"
                    :length="defaultAlias.length"
                    :current="currentPage"
                    :per-page="itemsPerPage"
                    @change="changePage"
                    />
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import AdminTemplate from '../components/AdminTemplate.vue'
import BooleanLabel from '../components/BooleanLabel.vue'
import EditAddAlias from '../components/EditAddAlias.vue'
import Pagination from '../components/Pagination.vue'

import apiService from '@/services/api.service'

export default {
    components: { AdminTemplate, BooleanLabel, EditAddAlias, Pagination },
    data() {
        return {
            defaultAlias: [],
            aliases: [],
            selectedAlias: null,
            loading: false,
            itemsPerPage: 20,
            currentPage: 1,
            searchInput: '',
        }
    },
    methods: {
        setSpaceItems() {
            this.aliases = this.defaultAlias.slice((this.itemsPerPage * (this.currentPage - 1)), (this.itemsPerPage * (this.currentPage)))
        },
        
        async getAliases() {
            this.loading = true
            const response = await apiService.post('all_alias')
            this.defaultAlias = response.data.posts.post
            this.defaultAlias.map((space) => space.style = JSON.parse(space.style))
            this.setSpaceItems()
            this.loading = false
        },

        updateAlias(space) {
            this.selectedAlias = space
            this.$modal.show('edit-add-alias')
        },

        async deleteAlias() {
            await apiService.post('remove_alias', {
                url_keyname: this.selectedAlias.url_keyname
            })
            this.$modal.hide('confirm-delete')
            this.getAliases()
        },

        changePage(index) {
            this.currentPage = index
            this.setSpaceItems()
        },

        searchAlias() {
            if (this.searchInput) {
                this.aliases = this.defaultAlias.filter((space) => space.name.toLowerCase().startsWith(this.searchInput.toLowerCase()));
            } else {
                this.setSpaceItems()
            }
        },

        displayAddModal() {
            this.selectedAlias = null
            this.$modal.show('edit-add-alias')
        },

        displayConfirmDeleteModal(space) {
            this.selectedAlias = space
            this.$modal.show('confirm-delete')
        },

        aliasUrl(space) {
            if (space.domain_name != 'null') {
                return 'https://' + space.domain_name
            }
            return 'https://savlog.3w-dev.com/' + space.url_keyname
        },

        openInNewTab(url) {
            window.open(url, '_blank').focus();
        }

    },
    mounted() {
        this.getAliases()
    }
}
</script>
