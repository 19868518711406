<template>
    <div>
        <label for="domain_name" class="block text-sm font-medium text-gray-900">Langue séléctionnée</label>
        <select v-model="selectedLang" class="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
            <option v-for="(lang, langIndex) in langs" :value="lang" :key="'lang-' + langIndex">
                {{lang.label}}
            </option>
        </select>

        <hr class="my-6">

        <div v-for="(exception, exceptionIndex) in exceptions" :key="'exception-' + exceptionIndex">
            <div class="mb-4">
                <div class="flex items-end grid-cols-5 gap-5">
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">clé</label>
                        <input v-model="exception.key" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div class="col-span-3 grow">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Nouveau text</label>
                        <input @input="translate(exceptionIndex)" v-model="exception.value" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                    </div>
                    <div>
                        <button @click="removeException(exceptionIndex)" class="mt-4 text-white bg-red-300 hover:bg-red-400 duration-200 py-3.5 px-4 rounded-xl text-xs font-light">
                            <i class="fas fa-trash mr-0.5"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button @click="addException" class="bg-gray-300 hover:bg-gray-400 duration-200 py-2 px-4 rounded-xl text-xs font-light">
            <i class="fas fa-plus mr-0.5"></i> Ajouter une exception
        </button>
        <!-- <button @click="addException" class="ml-1 bg-blue-300 hover:bg-blue-400 duration-200 py-2 px-4 rounded-xl text-xs font-light">
            <i class="fas fa-globe mr-0.5"></i> Traduire dans toutes les langues
        </button> -->
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {    
    props: {
        value: {
            default: []
        }
    },

    watch: {
        value: function(value) {
            this.dataSrc = value
        },
        selectedLang: async function(value) {
            if (this.value) {
                this.exceptions = this.value[value.lang]
            }
        },
        exceptions: {
            deep: true,
            handler(value) {
                this.dataSrc[this.selectedLang.lang] = value
                this.$emit('input', this.dataSrc);
            }
        }
    },

    data() {
        return {
            langs: [],
            dataSrc: null,
            selectedLang: null,
            defaultTexts: null,
            exceptions: [],
            timerTranslate: null,
        }
    },

    methods: {
        async getLangs() {
            let response = await apiService.post('getalllang', {})
            this.langs = response.data.posts.post
            this.selectedLang = this.langs[0]
        },

        addException() {
            this.exceptions.push({
                key: null,
                value: null
            })
        },

        removeException(index) {
            this.exceptions.splice(index, 1)
        },
        
        translate(index) {
            clearTimeout(this.timerTranslate)
            this.timerTranslate = setTimeout(async () => {
                for (var lang in this.dataSrc) {
                    if (lang != 'FR') {
                        let indexText = this.dataSrc[lang].findIndex((item) => item.key == this.dataSrc['FR'][index].key)
                        let response = await apiService.post('trad', {
                            langue: lang,
                            contenu: this.dataSrc['FR'][index].value
                        })
                        let translatedText = JSON.parse(response.data.posts.post).translations[0].text
                        if (indexText >= 0) {
                            this.dataSrc[lang][indexText].value = translatedText
                        } else {
                            this.dataSrc[lang].push({
                                key: this.dataSrc['FR'][index].key,
                                value: translatedText
                            })
                        }
                    }
                }
                this.$emit('input', this.dataSrc);
            }, 400)
        }

        // async getLangData(lang) {
        //     let response = await apiService.post('getlang', {langue: lang})
        //     response.data.posts.post[0].contenu = JSON.parse(response.data.posts.post[0].contenu)
        //     console.log(response.data.posts.post);
        // }
    },

    async mounted() {
        await this.getLangs()
        this.dataSrc = this.value

        if (this.dataSrc) {
            this.exceptions = this.dataSrc[this.selectedLang.lang]
        } else {
            this.dataSrc = {}
            this.langs.forEach((lang) => {
                this.dataSrc[lang.lang] = []
            })
            this.exceptions = this.dataSrc[this.selectedLang.lang]
        }

        if (this.exceptions.length <= 0) {
            this.addException()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
