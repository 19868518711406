<template>
    <div>

    </div>
</template>

<script>
    export default {
        mounted() {
            localStorage.clear()
            this.$router.push({name: 'Login'})
        }
    }
</script>

<style lang="scss" scoped>

</style>
