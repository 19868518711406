<template>
    <div>
        <span v-if="value" class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Oui</span>
        <span v-if="!value" class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Non</span>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
