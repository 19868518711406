<template>
    <div>
        <div class="grid lg:grid-cols-2 h-screen">
            <div class="hidden lg:block bg-slate-800 text-white text-center">
                <div class="flex flex-col justify-center h-screen">
                    <div class="mb-10">
                        <img class="mx-auto h-60" src="http://82.165.119.66/img/savinfo-admin/login.svg" alt="">
                    </div>
                    <h1 class="text-5xl font-extrabold">SavInfo</h1>
                    <span class="font-extralight opacity-70 text-xl mt-3">Espace administrateur</span>
                </div>
            </div>
            <div class="px-10 text-center">
                <div class="flex flex-col justify-center h-screen w-2/3 mx-auto">
                    <div class="mb-10">
                        <h1 class="text-5xl font-bold">Connectez-vous</h1>
                        <p class="font-light opacity-70 mt-4 text-lg">Vous devez être connecté pour accéder à l'espace administrateur</p>
                    </div>
                    <div class="bg-white shadow p-10 rounded-xl">
                        <div class="mb-6 text-left">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Nom d'utilisateur</label>
                            <input v-model="email" type="text" id="email" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                        </div>
                        <div class="mb-6 text-left">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Mot de passe</label>
                            <input v-model="password" type="password" id="password" class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5">
                        </div>
                        <button @click="login" type="button" class="mt-1 text-white bg-emerald-500 hover:bg-emerald-600 duration-200 rounded-lg text-sm px-6 py-4 mr-2 focus:outline-none">
                            Se connecter <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sha256 from 'js-sha256';
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            email: null,
            password: null
        }
    },
    methods: {
        async login() {
            if (!this.email || !this.password) {
                this.$toast.error('Formulaire incomplet')
                return
            }

            const password = sha256(this.password)
    
            let response = await apiService.post('connexion_back', {
                mail: this.email,
                password: password
            })

            if (response.data.posts.post == 1) {
                localStorage.setItem('user', JSON.stringify({
                    email: this.email
                }))
                this.$router.push({name: 'Home'})
                this.$toast.success('Vous êtes connecté')
            } else {
                this.$toast.error(response.data.posts.error)
            }
        }
    },

    async mounted() {
    }
}
</script>

<style lang="scss" scoped>

</style>
